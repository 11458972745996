/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
#home {
  width: 100%;
  height: calc(100vh - 86px);
  background-color: rgba(4, 12, 21, 0.8);
  overflow: hidden;
  position: relative;
}
#home .carousel, #home .carousel-inner, #home .carousel-item, #home .carousel-item::before {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}
#home .carousel-item {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
#home .carousel-item::before {
  content: "";
  background-color: rgba(4, 12, 21, 0.4);
}
#home .carousel-container {
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 0;
  top: 82px;
  left: 56px;
  right: 56px;
}
#home h2 {
  color: #fff;
  margin: 0;
  font-size: 42px;
  font-weight: 700;
  text-shadow: 0px 0px 12px rgba(0, 0, 0, 0.6);
}
#home p {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
  color: #fff;
  margin-top: 10px;
  font-size: 17px;
  text-shadow: 0px 0px 12px rgba(0, 0, 0, 0.6);
}
@media (min-width: 1200px) {
  #home p {
    width: 50%;
  }
}
#home .carousel-inner .carousel-item {
  transition-property: opacity;
  background-position: center;
  background-size: cover;
}
#home .carousel-inner .carousel-item,
#home .carousel-inner .active.carousel-item-start,
#home .carousel-inner .active.carousel-item-end {
  opacity: 0;
}
#home .carousel-inner .active,
#home .carousel-inner .carousel-item-next.carousel-item-start,
#home .carousel-inner .carousel-item-prev.carousel-item-end {
  opacity: 1;
  transition: 0.5s;
}
#home .carousel-inner .carousel-item-next,
#home .carousel-inner .carousel-item-prev,
#home .carousel-inner .active.carousel-item-start,
#home .carousel-inner .active.carousel-item-end {
  left: 0;
  transform: translate3d(0, 0, 0);
}
#home .carousel-control-next-icon, #home .carousel-control-prev-icon {
  background: none;
  font-size: 28px;
  line-height: 0;
  width: auto;
  height: auto;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 50px;
  transition: 0.3s;
  color: rgba(255, 255, 255, 0.5);
  width: 54px;
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
}
#home .carousel-control-next-icon:hover, #home .carousel-control-prev-icon:hover {
  background: rgba(255, 255, 255, 0.3);
  color: rgba(255, 255, 255, 1);
}

#home .carousel-indicators li {
  cursor: pointer;
  background: #fff;
  overflow: hidden;
  border: 0;
  width: 16px;
  height: 8px;
  border-radius: 50px;
  margin: 0px 3px;
  opacity: 0.6;
  transition: 0.3s;
}
#home .carousel-indicators li.active {
  opacity: 1;
  background: var(--secondaryColor);
}
#home .btn-get-started {
  font-family: "Raleway", sans-serif;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 14px 32px;
  border-radius: 4px;
  transition: 0.5s;
  line-height: 1;
  color: #fff;
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
  background: var(--secondaryColor);
  margin-top: 15px;
}
#home .btn-get-started:hover {
  background: #ef6445;
}
@media (max-width: 992px) {
  #home .carousel-container {
    text-align: center;
    top: 74px;
  }
}
@media (max-width: 768px) {
  #home h2 {
    font-size: 30px;
  }

  #home p {
    font-size: 15px;
  }
}
@media (min-width: 1024px) {
  #home .carousel-control-prev, #home .carousel-control-next {
    width: 5%;
  }
}
@media (max-height: 500px) {
  #home {
    height: 120vh;
  }
}


#home .carousel-item.active .title {
  position: relative;
  animation: carouselTextfadeUp 1s;
}


#home .carousel-item.active .subtitle {
  position: relative;
  animation: carouselTextfadeDown 1.2s;
}


@keyframes carouselTextfadeUp {
  0% {
    top: -32px;
    opacity: 0;
  }

  100% {
    top: 0px;
    opacity: 1;
  }
}

@keyframes carouselTextfadeDown {
  0% {
    bottom: -18px;
    opacity: 0;
  }

  50% {
    bottom: -18px;
    opacity: 0;
  }

  100% {
    bottom: 0px;
    opacity: 1;
  }
}