/*
 *  CSS Global da aplicação
 */
:root {
    --primaryColor: #080806;
    --primaryColorLight: #161612;
    --secondaryColor: #96032e;

    --onlineReservationLinkBackground: rgba(150, 3, 46, 0.1);
}



/*--------------------------------------------------------------
# Helpers
--------------------------------------------------------------*/
.cursor-pointer {
  cursor: pointer !important;
}

.animation-fast-duration {
  animation-duration: 1s !important;
}

.small-pulse-animation {
  animation: small-pulse 2s infinite;
}

.pulse-animation {
  animation: pulse 2s infinite;
}

.show-sofly-animation {
  animation: showSofly 0.6s;
}

@keyframes small-pulse {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.1);
  }
  100% {
    box-shadow: 0 0 0 12px rgba(0, 0, 0, 0);
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
  }
  100% {
    box-shadow: 0 0 0 16px rgba(0, 0, 0, 0);
  }
}


@keyframes showSofly {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}


/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
body {
  font-family: "Open Sans", sans-serif;
  color: #444444;
}

a {
  color: var(--secondaryColor);
  text-decoration: none;
}

a:hover {
  color: #B4214C;
  text-decoration: none;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Raleway", sans-serif;
}


@media only screen and (max-width: 992px) {
  .horizontal-scroll-images {
    display: flex;
    flex-wrap: nowrap;
    overflow: auto;
  }
}

/*--------------------------------------------------------------
# Preloader
--------------------------------------------------------------*/
#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  overflow: hidden;
  background: #fff;
}

#preloader:before {
  content: "";
  position: fixed;
  top: calc(50% - 30px);
  left: calc(50% - 30px);
  border: 6px solid var(--secondaryColor);
  border-top-color: white;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  -webkit-animation: animate-preloader 1s linear infinite;
  animation: animate-preloader 1s linear infinite;
}

@-webkit-keyframes animate-preloader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate-preloader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/*--------------------------------------------------------------
# Back to top button
--------------------------------------------------------------*/
.back-to-top {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  right: 15px;
  bottom: 15px;
  z-index: 996;
  background: var(--secondaryColor);
  width: 40px;
  height: 40px;
  border-radius: 4px;
  transition: all 0.4s;
}
.back-to-top i {
  font-size: 28px;
  color: #fff;
  line-height: 0;
}
.back-to-top:hover {
  background: #f06f54;
  color: #fff;
}
.back-to-top.active {
  visibility: visible;
  opacity: 1;
}


/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
section {
  padding: 120px 0 20px 0;
}

.section-bg {
  background: linear-gradient(transparent, #f5f5f5, #f5f5f5, #f5f5f5, transparent);
}

.section-title {
  padding-bottom: 40px;
}
.section-title h2 {
  font-size: 14px;
  font-weight: 500;
  padding: 0;
  line-height: 1px;
  margin: 0 0 5px 0;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #aaaaaa;
  font-family: "Poppins", sans-serif;
}
.section-title h2::after {
  content: "";
  width: 120px;
  height: 1px;
  display: inline-block;
  background: var(--secondaryColor);
  margin: 4px 10px;
}
.section-title p {
  margin: 0;
  margin: 0;
  font-size: 28px;
  font-weight: 700;
  text-transform: uppercase;
  font-family: "Poppins", sans-serif;
  color: var(--primaryColor);
}


@media (min-width: 1024px) {
  section {
    padding: 120px 0 60px 0;
  }
}
