/*--------------------------------------------------------------
# Services
--------------------------------------------------------------*/
.services .icon-box {
  text-align: center;
  background: #fefefe;
  box-shadow: 0px 5px 90px 0px rgba(110, 123, 131, 0.15);
  padding: 80px 20px;
  transition: all ease-in-out 0.3s;
  cursor: pointer;
  border-radius: 4px;
  width: 100%;
}
.services .icon-box .icon {
  margin: 0 auto;
  width: 64px;
  height: 64px;
  background: var(--secondaryColor);
  border-radius: 5px;
  transition: all 0.3s ease-out 0s;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  transform-style: preserve-3d;
  position: relative;
  z-index: 2;
}
.services .icon-box .icon i {
  color: #fff;
  font-size: 28px;
}
.services .icon-box .icon::before {
  position: absolute;
  content: "";
  left: -8px;
  top: -8px;
  height: 100%;
  width: 100%;
  background: var(--secondaryColor);
  opacity: 0.4;
  border-radius: 5px;
  transition: all 0.3s ease-out 0s;
  transform: translateZ(-1px);
  z-index: 1;
}
.services .icon-box h4 {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 24px;
}
.services .icon-box h4 a {
  color: var(--primaryColor);
}
.services .icon-box p {
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}
.services .icon-box:hover {
  background: var(--secondaryColor);
  border-color: var(--secondaryColor);
}
.services .icon-box:hover .icon {
  background: #fff;
}
.services .icon-box:hover .icon i {
  color: var(--secondaryColor);
}
.services .icon-box:hover .icon::before {
  background: #fff;
  opacity: 0.5;
}
.services .icon-box:hover h4 a, .services .icon-box:hover p {
  color: #fff;
}


@media only screen and (max-width: 992px) {
  .services-row {
    flex-wrap: nowrap;
    overflow-x: auto;
    padding: 20px 0px;
  }

  .services .icon-box {
    text-align: center;
    background: #fefefe;
    box-shadow: 0px 5px 30px 0px rgba(110, 123, 131, 0.15);
  }
}