/*--------------------------------------------------------------
# Slider
--------------------------------------------------------------*/
.slider-background {
    position: fixed;
    overflow: hidden;
    top: 0;
    right: 0;
    background: rgba(9, 9, 9, 0.9);
    transition: 0.3s;
    z-index: 9991;
    width: 100%;
    height: 100%;
    transition: 0.2s;
    visibility: hidden;
    display: none;
    opacity: 0;
  }

  .slider-background.actived {
    transition: 0.2s;
    visibility: visible;
    display: block;
    opacity: 1;
  }
  
  .slider-background i.close {
    font-size: 18px;
    cursor: pointer;
    line-height: 0;
    opacity: 0.9;
    color: #fff;
    position: absolute;
    top: 0px;
    right: 0px;
    z-index: 998;
    padding: 30px;
    transition: 0.2s;
  }

  .slider-background i.close:hover {
    transform: scale(1.2);
    transition: 0.2s;
  }


  .controls {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 60px;
      background-color: rgba(0, 0, 0, 0.6);
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 998;
  }

  .controls i {
        margin: 0px 28px;
        color: #fff;
        opacity: 0.9;
        font-size: 32px;
        transition: 0.2s;
        cursor: pointer;
  }

  .controls i:hover {
    transition: 0.2s;
    transform: scale(1.2);
}