/*--------------------------------------------------------------
# Specials
--------------------------------------------------------------*/
.specials .member {
  position: relative;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  padding: 0px;
  border-radius: 5px;
  background: #fff;
  transition: 0.5s;
  margin: 12px 0px;
  cursor: pointer;
  height: 260px;
}

.specials .member .pic {
  overflow: hidden;
  width: 100%;
  height: calc(100% - 72px);
  border-radius: 4px 4px 0px 0px;
  position: relative;
  flex: 1;
}

.specials .member .pic .lazyload-wrapper {
  width: 100%;
  height: 100%;
}

.specials .member .pic .lazyload-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: bottom;
  transition: ease-in-out 0.3s;
}

.specials .member:hover {
  transform: translateY(-10px);
}

.specials .member .member-info {
  padding: 0px 20px;
  flex: 2;
  display: flex;
  align-items: center;
  height: 72px;
}

.specials .member h4 {
  font-weight: 700;
  margin-bottom: 5px;
  font-size: 20px;
  color: var(--primaryColor);
}

.specials .member span {
  display: block;
  font-size: 14px;
  padding-bottom: 10px;
  position: relative;
  /* font-weight: 500; */
}
.specials .member span::after {
  content: "";
  position: absolute;
  display: block;
  width: 50px;
  height: 1px;
  background: #cbd6e9;
  bottom: 0;
  left: 0;
}
.specials .member p {
  margin: 10px 0 0 0;
  font-size: 14px;
}
.specials .member .social {
  margin-top: 12px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.specials .member .social a {
  transition: ease-in-out 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  width: 32px;
  height: 32px;
  background: #eff2f8;
}
.specials .member .social a i {
  color: var(--primaryColor);
  font-size: 16px;
  margin: 0 2px;
}
.specials .member .social a:hover {
  background: #47b2e4;
}
.specials .member .social a:hover i {
  color: #fff;
}
.specials .member .social a + a {
  margin-left: 8px;
}




/* MODAL */
.image-list {
  margin: 20px 0px;
  display: flex;
  flex-wrap: wrap;
  min-height: 200px;
}

.image-list .image-item {
  padding: 4px;
  width: 25%;
  cursor: pointer;
  position: relative;
}

.image-list .image-item img {
  width: 100%;
  height: 160px;
  object-fit: cover;
}


@media only screen and (max-width: 901px) {
  .image-list .image-item {
    width: 33%;
  }

  .image-list .image-item img {
    height: 120px;
  }
}

@media only screen and (max-width: 576px) {
  .image-list .image-item {
    width: 50%;
  }

  .image-list .image-item img {
    height: 160px;
  }
}