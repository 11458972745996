
.loading {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    transition: 0.2s;
    visibility: hidden;
    opacity: 0;
    display: none;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
}


.loading.actived {
    visibility: visible;
    opacity: 1;
}