
/*--------------------------------------------------------------
  # Trigger
  --------------------------------------------------------------*/
.languages-trigger {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 20px;
    padding: 12px 18px;
    border-radius: 8px;
    cursor: pointer;
    transition: 0.2s;
    width: 80px;
}

.languages-trigger:hover {
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
    transition: 0.2s;
}

.languages-trigger > img {
    height: 20px;
    margin-right: 8px;
}

.languages-trigger > span {
    font-size: 12px;
    color: #000;
}


@media only screen and (max-width: 991px) {
    .languages-trigger {
        margin-left: 0px;
        margin-right: 20px;
        padding: 12px 16px;
        width: auto;
    }

    .languages-trigger:hover {
        box-shadow: none;
    }

    .languages-trigger > img {
        margin: 0px;
    }

    .languages-trigger > span {
        display: none;
    }
}



/*--------------------------------------------------------------
  # Modal
  --------------------------------------------------------------*/
#languageModal .modal-header {
    border: none;
}

#languageModal .modal-title {
    font-size: 1.25rem;
    font-weight: 500;
    margin: 0px;
    color: #000;
}


#languageModal .modal-body .language-item {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 12px;
    border-radius: 20px;
    cursor: pointer;
    transition: 0.2s;
    margin-bottom: 8px;
    position: relative;
}

#languageModal .modal-body .language-item.selected:before {
    content: "";
    position: absolute;
    right: 24px;
    top: 20px;
    width: 8px;
    height: 16px;
    border: 3px solid var(--secondaryColor);
    border-top: none;
    border-left: none;
    transform: rotate(45deg);
}

#languageModal .modal-body .language-item:hover,
#languageModal .modal-body .language-item.selected {
    background-color: #eee;
    transition: 0.2s;
}

#languageModal .modal-body .language-item > img {
    height: 100%;
    margin-right: 16px;
}

#languageModal .modal-body .language-item > span {
    color: #000;
}
