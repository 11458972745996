
.floatingActionBar {
    position: fixed;
    bottom: 0px;
    right: 0px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    padding: 16px;
    z-index: 992;
    flex-direction: row-reverse;
}

.floatingActionBar .action-item {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #106eea;
    padding: 0px 32px;
    color: #fafafa;
    border-radius: 4px;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.6);
    margin: 0px 0px 0px 24px;
    transition: 0.2s;
    animation: pulse 1s infinite;
}



/* Midias sociais */
.floatingActionBar .action-item.social-link {
    border-radius: 100%;
    padding: 0px;
    height: 60px;
    width: 60px;
    transition: 0.2s;
}

.floatingActionBar .action-item.social-link > i {
    font-size: 26px;
}


.floatingActionBar .action-item.social-link.whatsapp {
    background-color: #11BF52;
}

.floatingActionBar .action-item.social-link.facebook {
    background-color: #3b5998;
}

.floatingActionBar .action-item.social-link.instagram { 
    background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 
}