  /*--------------------------------------------------------------
  # Top Bar
  --------------------------------------------------------------*/
  #topbar {
    background: var(--secondaryColor);
    /* height: 40px; */
    height: 0px;
    font-size: 14px;
    transition: all 0.5s;
    color: #fff;
    padding: 0;
  }
  #topbar .contact-info i {
    font-style: normal;
    color: #fff;
    margin-right: 20px;
    font-size: 12px;
  }

  #topbar .contact-info i a, #topbar .contact-info i span {
    font-family: "Open Sans", sans-serif;
    padding-left: 10px;
    color: #fff;
  }
  #topbar .contact-info i a {
    line-height: 0;
    transition: 0.3s;
    transition: 0.3s;
  }
  #topbar .contact-info i a:hover {
    color: #fff;
    text-decoration: underline;
  }
  #topbar .social-links a {
    color: rgba(255, 255, 255, 0.7);
    line-height: 0;
    transition: 0.3s;
    margin-left: 20px;
  }
  #topbar .social-links a:hover {
    color: white;
  }


  @media only screen and (max-width: 901px) {
    #topbar .contact-info i {
      margin-right: 0px;
    }
  }
  
  /*--------------------------------------------------------------
  # Header
  --------------------------------------------------------------*/
  #header {
    background: #fff;
    transition: all 0.5s;
    z-index: 997;
    height: 86px;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
    position: sticky;
    top: 0;
  }

  #header > .container {
    height: 100%;
  }

  #header .logo {
    font-size: 30px;
    margin: 0;
    padding: 0;
    line-height: 1;
    font-weight: 600;
    letter-spacing: 0.8px;
    font-family: "Poppins", sans-serif;
  }
  #header .logo a {
    color: #222222;
  }
  #header .logo a span {
    color: #106eea;
  }
  #header .logo img {
    max-height: 76px;
  }
  
  .scrolled-offset {
    margin-top: 70px;
  }

  @media only screen and (max-width: 576px) {
    #header .logo img {
      max-height: 48px;
    }
  }
  
  /*--------------------------------------------------------------
  # Navigation Menu
  --------------------------------------------------------------*/
  /**
  * Desktop Navigation 
  */
  .navbar {
    padding: 0;
  }
  .navbar ul {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
    align-items: center;
  }
  .navbar li {
    position: relative;
  }
  .navbar > ul > li {
    white-space: nowrap;
    padding: 0px;
    margin-left: 8px;
  }
  .navbar a, .navbar a:focus {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 3px;
    font-size: 14px;
    font-weight: 600;
    color: #222222;
    white-space: nowrap;
    transition: 0.3s;
    position: relative;
    height: 86px;
    padding: 0px 16px;
  }

  .navbar a i, .navbar a:focus i {
    font-size: 12px;
    line-height: 0;
    margin-left: 5px;
  }
  .navbar > ul > li > a:before {
    content: "";
    position: absolute;
    width: calc(100% - 16px);
    height: 2px;
    bottom: 26px;
    left: 8px;
    background-color: var(--secondaryColor);
    visibility: hidden;
    width: 0px;
    transition: all 0.3s ease-in-out 0s;
  }
  .navbar a:hover:before, .navbar li:hover > a:before, .navbar .active:before {
    visibility: visible;
    width: calc(100% - 16px);
  }
  .navbar a:hover, .navbar .active, .navbar .active:focus, .navbar li:hover > a {
    color: var(--secondaryColor);
  }
  .navbar .dropdown ul {
    display: block;
    position: absolute;
    left: 28px;
    top: calc(100% + 30px);
    margin: 0;
    padding: 10px 0;
    z-index: 99;
    opacity: 0;
    visibility: hidden;
    background: #fff;
    box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
    transition: 0.3s;
  }
  .navbar .dropdown ul li {
    min-width: 200px;
  }
  .navbar .dropdown ul a {
    padding: 10px 20px;
    font-weight: 400;
  }
  .navbar .dropdown ul a i {
    font-size: 12px;
  }
  .navbar .dropdown ul a:hover, .navbar .dropdown ul .active:hover, .navbar .dropdown ul li:hover > a {
    color: var(--secondaryColor);
  }
  .navbar .dropdown:hover > ul {
    opacity: 1;
    top: 100%;
    visibility: visible;
  }
  .navbar .dropdown .dropdown ul {
    top: 0;
    left: calc(100% - 30px);
    visibility: hidden;
  }
  .navbar .dropdown .dropdown:hover > ul {
    opacity: 1;
    top: 0;
    left: 100%;
    visibility: visible;
  }
  @media (max-width: 1366px) {
    .navbar .dropdown .dropdown ul {
      left: -90%;
    }
    .navbar .dropdown .dropdown:hover > ul {
      left: -100%;
    }
  }
  
  /**
  * Mobile Navigation 
  */
  .mobile-nav-toggle {
    color: #222222;
    font-size: 22px;
    cursor: pointer;
    display: none;
    line-height: 0;
    opacity: 0.8;
  }
  .mobile-nav-toggle.actived {
    color: #fff;
    font-size: 18px;
  }
  
  @media (max-width: 991px) {
    .mobile-nav-toggle {
      display: block;
    }
  
    .navbar ul {
      display: none;
    }

    .navbar > ul > li {
      width: calc(100% - 16px);
    }


    .navbar-mobile {
      position: fixed;
      overflow: hidden;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      background: rgba(9, 9, 9, 0.9);
      transition: 0.3s;
      z-index: 9991;
    }
    .navbar-mobile .mobile-nav-toggle {
      position: absolute;
      top: 30px;
      right: 25px;
    }
    .navbar-mobile ul {
      display: block;
      position: absolute;
      top: 55px;
      right: 15px;
      bottom: 15px;
      left: 15px;
      padding: 10px 0;
      background-color: #fff;
      overflow-y: auto;
      transition: 0.3s;
    }
    .navbar-mobile a, .navbar-mobile a:focus {
      height: unset;
      padding: 10px 12px;
      font-size: 16px;
      color: #222222;
      border-radius: 2px;
    }
    .navbar-mobile > ul > li {
      padding: 0;
    }
    
    .navbar-mobile a:hover, .navbar-mobile .active, .navbar-mobile li:hover > a {
      color: var(--secondaryColor);
    }

    .navbar-mobile a:before {
      content: "";
      position: absolute;
      width: 3px !important;
      height: calc(100% - 12px) !important;
      left: unset !important;
      top: 6px;
      right: 6px;
      background-color: var(--secondaryColor);
      visibility: hidden !important;
      transition: unset !important;
    }

    .navbar-mobile a:hover:before, .navbar-mobile a:focus:before, .navbar-mobile a.active:before {
      visibility: visible !important;
    }


    .navbar-mobile .getstarted, .navbar-mobile .getstarted:focus {
      margin: 15px;
    }
    .navbar-mobile .dropdown ul {
      position: static;
      display: none;
      margin: 10px 20px;
      padding: 10px 0;
      z-index: 99;
      opacity: 1;
      visibility: visible;
      background: #fff;
      box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
    }
    .navbar-mobile .dropdown ul li {
      min-width: 200px;
    }
    .navbar-mobile .dropdown ul a {
      padding: 10px 20px;
    }
    .navbar-mobile .dropdown ul a i {
      font-size: 12px;
    }
    .navbar-mobile .dropdown ul a:hover, .navbar-mobile .dropdown ul .active:hover, .navbar-mobile .dropdown ul li:hover > a {
      color: #106eea;
    }
    .navbar-mobile .dropdown > .dropdown-active {
      display: block;
    }
  }


  .onlineReservation {
    background-color: var(--onlineReservationLinkBackground);
  }